import Sidebar from '@/components/shared/TemplateParts/Sidebar'
import ZendeskProvider from '@/providers/shared/ZendeskProvider'
import SubscriptionStatusProvider from '@/providers/subscription'
import {
  getAdminCookie,
  isPrivateLoggedIn,
  login,
  removeAdminCookie
} from '@/utils/lib'
import { getBreadCrumbItems } from '@/utils/static-helpers/breadcrumb-helper'
import { pageTitle } from '@/utils/static-helpers/pageTitle'
import {
  Bars3Icon,
  ChevronRightIcon,
  XMarkIcon
} from '@heroicons/react/24/solid'
import { Breadcrumb } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import QureosLogoBlue from 'assets/logos/QureosLogoBlue'
import clsx from 'clsx'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, ReactNode, useState } from 'react'
import { useRecoilState } from 'recoil'
import { apprenticeSidebarOpen, sidebarOpen, user as userAtom } from 'src/atoms'
import withRedirectUrl from 'src/hocs/withRedirectUrl'
import { ProfileProvider } from 'src/providers/shared'
import { SeoOGMetaType } from 'src/types/seo'
import { User, UserRole } from 'src/types/shared/graphql'
import DefaultSideMenu from '../../TemplateParts/DefaultSideMenu'
import { ShouldRender } from '../../misc'
import Script from 'next/script'

const DashboardTemplateV2: FC<{
  title?: string
  onError?: () => void
  showBreadCrumbs?: boolean
  className?: string
  meta?: string
  metaCanonical?: string
  seoOgMeta?: SeoOGMetaType
  useNoIndex?: boolean
  useNoIndexFollow?: boolean
  children?: ReactNode
  searchSlug?: string
}> = ({
  title,
  children,
  onError,
  showBreadCrumbs = false,
  className,
  meta: metaDescription,
  metaCanonical,
  seoOgMeta,
  useNoIndex = false,
  useNoIndexFollow = false,
  searchSlug
}) => {
  const screens = useBreakpoint()
  const router = useRouter()
  const { eventtype, eventID } = router.query
  const [user, setUser] = useRecoilState<User>(userAtom)
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState<boolean>(
    user?.role === UserRole.Student ? apprenticeSidebarOpen : sidebarOpen
  )
  const isPrivateUser: boolean = isPrivateLoggedIn()

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)

  const handleLoginBack = (): void => {
    const adminToken: string = getAdminCookie()
    login(adminToken)
    removeAdminCookie()
    router.push('/admin/dashboard')
    setUser(undefined)
  }

  const breadCrumbItems = getBreadCrumbItems({
    searchSlug,
    eventtype,
    eventID,
    routePath: router.asPath,
    screens
  })

  const breadCrumbs = breadCrumbItems[router.pathname]
  return (
    <>
      <Head>
        <title>{pageTitle(title)}</title>
        <meta name="description" content={metaDescription} />
        <ShouldRender check={useNoIndexFollow}>
          <meta name="robots" content="noindex,follow" />
        </ShouldRender>
        <ShouldRender check={useNoIndex}>
          <meta name="robots" content="noindex" />
        </ShouldRender>
        {metaCanonical && <link rel="canonical" href={metaCanonical} />}
        {seoOgMeta && (
          <>
            <meta property="og:title" content={seoOgMeta.title} />
            <meta property="og:description" content={seoOgMeta.description} />
            <meta property="og:url" content={seoOgMeta.url} />
            <meta property="og:site_name" content={seoOgMeta.siteName} />
          </>
        )}
      </Head>
      <ProfileProvider onError={onError}>
        <ZendeskProvider />
        <SubscriptionStatusProvider>
          <div
            className={clsx(
              'w-full h-auto min-h-screen flex bg-white',
              isSidebarOpen ? 'overflow-hidden' : ''
            )}
          >
            {user && <Sidebar bgColor="white" role={user.role} />}
            <div
              className="flex flex-col w-full bg-qureosBgGray"
              onClick={() => {
                isSidebarOpen ? setIsSidebarOpen(false) : ''
              }}
            >
              {/* mobile headers */}
              <div
                className={clsx(
                  'flex flex-col w-full md:min-h-screen bg-white sticky top-0 md:hidden z-[150]',
                  { 'border-b border-b-gray-200': !isHamburgerMenuOpen }
                )}
              >
                <div className="flex items-center justify-between px-4 h-14">
                  <div className="flex items-center justify-center gap-x-2.5">
                    <div className="flex items-center justify-center flex-shrink-0 w-10">
                      <QureosLogoBlue
                        className="cursor-pointer fill-qureosSecondary"
                        onClick={() => router.push('/')}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => setIsHamburgerMenuOpen(prev => !prev)}
                    className="w-6 cursor-pointer"
                  >
                    {isHamburgerMenuOpen ? (
                      <XMarkIcon className="w-6" />
                    ) : (
                      <Bars3Icon className="w-6" />
                    )}
                  </div>
                </div>

                {isHamburgerMenuOpen && (
                  <div className="absolute flex flex-col borderless-shadow border-t overflow-y-auto border-t-gray-200 w-full bg-white top-[56px] min-h-[94vh]">
                    <DefaultSideMenu
                      showLabel
                      isMobile
                      role={user?.role}
                      user={user}
                    />
                  </div>
                )}
              </div>
              {isPrivateUser && (
                <p className="font-medium text-white bg-indigo-600">
                  <span className="hidden md:inline">
                    {`Logged in as ${user?.firstName} ${user?.lastName}`}
                  </span>
                  <span className="block sm:ml-2 sm:inline-block">
                    <a
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.preventDefault()
                        handleLoginBack()
                      }}
                      href="#"
                      className="font-bold text-white underline"
                    >
                      {' '}
                      Go back to the Account{' '}
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </span>
                </p>
              )}
              <ShouldRender check={showBreadCrumbs}>
                {breadCrumbs && (
                  <Breadcrumb
                    className="p-4 lg:px-8 font-inter bg-qureosBgGray w-full md:w-[95%] mx-auto"
                    separator={
                      <ChevronRightIcon className="h-5 w-4 pb-[1px] text-gray-400 inline" />
                    }
                  >
                    {breadCrumbs.map((item, index) => {
                      return (
                        <Breadcrumb.Item
                          className="cursor-pointer"
                          key={item.key as string}
                        >
                          {item.path ? (
                            <Link href={item.path} key={index}>
                              <span className="font-medium text-gray-500">
                                {item.label}
                              </span>
                            </Link>
                          ) : (
                            <span className="font-medium text-gray-500">
                              {item.label}
                            </span>
                          )}
                        </Breadcrumb.Item>
                      )
                    })}
                  </Breadcrumb>
                )}
              </ShouldRender>
              <div className={className}>{children}</div>
              {user &&
                user.role !== UserRole.Professional &&
                process.env.APP_ENV === 'production' && (
                  <Script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                    (function(){
                    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                    s1.async=true;
                    s1.src='https://embed.tawk.to/67bd713978fce4190b5b6dcd/1iku04il7';
                    s1.charset='UTF-8';
                    s1.setAttribute('crossorigin','*');
                    s0.parentNode.insertBefore(s1,s0);
                    })();`
                    }}
                  />
                )}
            </div>
          </div>
        </SubscriptionStatusProvider>
      </ProfileProvider>
    </>
  )
}

export default withRedirectUrl(DashboardTemplateV2)
