import { PaginatedResponse } from 'src/types/data'
import {
  IAllRecommendationsParams,
  IRecommendOneCandidateParams,
  IRecommendMultipleCandidateParams,
  IRecommendationMetaData
} from 'src/types/data/recommendation'
import apiClient from './base'
import { Gender } from '@qureos/types'
import { isEmpty } from 'lodash'

export const getAllRecommendedCandidates = ({
  ...params
}: IAllRecommendationsParams): Promise<PaginatedResponse<any>> => {
  const queryParams = new URLSearchParams()
  let filteredGenders = params.genders

  if (
    Array.isArray(filteredGenders) &&
    ((filteredGenders.includes(Gender.MALE) &&
      filteredGenders.includes(Gender.FEMALE)) ||
      filteredGenders.includes(Gender.ALL))
  ) {
    filteredGenders = undefined
  }
  Object.keys(params).forEach((key: keyof IAllRecommendationsParams) => {
    const queryValue = params[key].toString()
    if (key === 'diversities') {
      params[key].forEach(item => queryParams.append(key + '[]', item))
    } else if (key === 'countries') {
      params[key].forEach(item => queryParams.append(key + '[]', item))
    } else if (key === 'genders') {
      if (Array.isArray(filteredGenders)) {
        filteredGenders.forEach(item => queryParams.append(key + '[]', item))
      }
    } else if (key === 'languages') {
      params[key].forEach(item => queryParams.append(key + '[]', item))
    } else if (key === 'nationalities') {
      params[key].forEach(item => queryParams.append(key + '[]', item))
    } else if (key === 'educationDegreeIds') {
      params[key].forEach(item => queryParams.append(key + '[]', item))
    } else if (key === 'educationFieldIds') {
      params[key].forEach(item => queryParams.append(key + '[]', item))
    } else if (key === 'workHistoryIndustryIds') {
      params[key].forEach(item => queryParams.append(key + '[]', item))
    } else if (key === 'selectionMethod') {
      if (!isEmpty(params[key]) && queryValue !== 'all') {
        queryParams.append(key, queryValue)
      }
    } else if (key === 'isStarred') {
      if (queryValue === 'true') {
        queryParams.append(key, queryValue)
      }
    } else {
      queryParams.append(key, queryValue.toString())
    }
  })
  return apiClient
    .get(`/recommendations?${queryParams.toString()}`)
    .then(resp => resp.data)
}

export const getRecommendationMetaData = ({
  ...params
}: IRecommendationMetaData): Promise<any> => {
  const queryParams = new URLSearchParams()
  Object.keys(params).forEach((key: keyof IRecommendationMetaData) => {
    const queryValue = params[key].toString()

    queryParams.append(key, queryValue.toString())
  })
  return apiClient
    .get(`/recommendations/filter-availability?${queryParams.toString()}`)
    .then(resp => resp.data)
}

export const generateRecommendationReason = (
  jobId: string,
  apprenticeId: string
) => {
  return apiClient
    .get(
      `/recommendations/generate-recommendation-reason?apprenticeId=${apprenticeId}&&jobId=${jobId}`,
      {
        timeout: 90 * 1000
      }
    )
    .then(resp => resp.data)
}

export const generateReasonUsingRecommendationId = (id: string) => {
  return apiClient
    .get(`/recommendations/${id}/reason`, {
      timeout: 90 * 1000
    })
    .then(resp => resp.data)
}

export const recommendOneCandidates = (
  id,
  body: IRecommendOneCandidateParams
) => {
  return apiClient.patch(`/recommendations/${id}`, body).then(resp => resp.data)
}

export const recommendMultipleCandidates = (
  body: IRecommendMultipleCandidateParams
) => {
  const formattedBody = {
    ...body,
    ids:
      Array.isArray(body?.ids) && Array.isArray(body?.ids[0])
        ? body?.ids[0]
        : body?.ids
  }
  return apiClient
    .patch(`/recommendations`, formattedBody)
    .then(resp => resp.data)
}

export const declineRecommendation = (body: {
  captcha: string
  decline: string
}): Promise<any> => {
  return apiClient
    .post(`/recommendations/decline`, body)
    .then(resp => resp.data)
}

/* api removed from BE */
export const getRecommendedCandidatesForConversationsJobs = body => {
  return apiClient
    .post(`/recommendations/generate`, { ...body }, { timeout: 180000 })
    .then(resp => resp.data)
}

export const generateRecommendationOutreach = (id: string) => {
  return apiClient
    .post(`/recommendations/${id}/outreach`)
    .then(resp => resp.data)
}

export const patchRecommendation = (id: string, body) => {
  return apiClient
    .patch(`/recommendations/${id}`, { ...body })
    .then(resp => resp.data)
}

export const patchMultipleRecommendation = body => {
  return apiClient
    .patch('/recommendations', { ...body })
    .then(resp => resp.data)
}

export const checkIsApplied = (decline: string): Promise<any> => {
  return apiClient.get(`/recommendations/${decline}`).then(resp => resp.data)
}

/* Excluded/rejected Candidate */
export const rejectRecommendedCandidate = body => {
  return apiClient
    .post(`/recommendations/reject`, { ...body })
    .then(resp => resp.data)
}

export function unlockCandidate(params: {
  recommendationId: string
}): Promise<any>
export function unlockCandidate(params: {
  talentPoolId: string
  candidateId: string
}): Promise<any>
export function unlockCandidate(params: {
  recommendationId?: string
  talentPoolId?: string
  candidateId?: string
}): Promise<any> {
  if (params.recommendationId) {
    return apiClient
      .post(`/recommendations/${params.recommendationId}/unlock`)
      .then(resp => resp.data)
  }

  if (params.talentPoolId && params.candidateId) {
    return apiClient
      .post(
        `/talent-pools/${params.talentPoolId}/candidates/${params.candidateId}/unlock`
      )
      .then(resp => resp.data)
  }

  throw new Error('Invalid parameters provided to unlockCandidate')
}

export const bulkUnlock = async (params: { recommendationIds: string[] }) => {
  const unlockPromises = params.recommendationIds.map(recommendationId =>
    unlockCandidate({ recommendationId })
  )
  return await Promise.all(unlockPromises)
}

export const moveApplicantRecommendation = (
  id: string,
  params: {
    statusId?: string
    stage?: string
    substage?: string
  }
) => {
  return apiClient
    .post(`/recommendations/${id}/pipeline`, {
      ...params
    })
    .then(resp => resp.data)
}

export const recommendationsSeen = (recommendationIds: string[]) => {
  return apiClient
    .post(`/recommendations/seen`, { recommendationIds })
    .then(resp => resp.data)
}
